import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { globalExportService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';
import { PageLoader } from '../../../shared_elements';

const ImportInventory = ({ getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [previewFile, setPreviewFile] = useState({ modal: false, data: null, singlepreview: null })
    const [isLoading, setLoading] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);

    const onImport = () => {
        var formData = new FormData();
        formData.append('files', files[0]);
        setLoading(true)
        globalPostService(`console/inventory/import/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setModal(false); getResponseBack();
                }
                setLoading(false)
            })
    }
    const exportInventorySample = () => {
        setLoading(true);
        globalExportService(`console/inventory/export/`)
            .then(response => {
                setLoading(false);
                downloadFileType(response?.data, 'Consignment Sample', 'xlsx')
            });
    }
    const handleChipClick = (event) => {
        setPageLoader(true);
        let formData = new FormData();
        formData.append('file', files.find(item => item?.path === event));
        globalPostService(`camo/preview/file/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    setPageLoader(false);
                    setPreviewFile({ modal: true, data: response?.data, singlepreview: false })
                }
            });
    };
    return (
        <>
            <Button onClick={() => setModal(true)} color='primary' size='small' variant='outlined'>IMPORT</Button>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                    maxWidth={'md'}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Consignment
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{ width: '380px' }}>
                            <p>Please refer the sample sheet, <a style={{ color: '#3f51b5', cursor: 'pointer' }} onClick={exportInventorySample}>Download</a></p>
                            <div>
                                <DropzoneArea
                                    filesLimit={1}
                                    maxFileSize={104857600}
                                    acceptedFiles={['.xls, .xlsx']}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    showPreviews={true}
                                    dropzoneText={<p>Drag & Drop file Or Click Here </p>}
                                    dropzoneClass="drag-drop-cnt"
                                    maxWidth="sm"
                                    showAlerts={['info', 'error']}
                                    alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                    clearOnUnmount={true}
                                    onChange={(files) => setFiles(files)}
                                    previewChipProps={{
                                        onClick: (event) => {
                                            handleChipClick(event?.target.textContent);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} color="primary" size='small' onClick={() => { setModal(false) }}>Close</Button>
                        <Button size='small' disabled={isLoading || !files.length} color="primary" variant="contained" onClick={() => onImport()}>
                            {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog> : null
            }
            {
                previewFile?.modal ?
                    <Dialog
                        open={previewFile?.modal}
                        onClose={() => setPreviewFile({ modal: false, data: null })}
                        style={{ height: "100vh" }}
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogTitle>
                        Consignment Preview
                        </DialogTitle>
                        <DialogContent dividers>
                            {previewFile?.data?.file_name?.endsWith('.xls') || previewFile?.data?.file_name?.endsWith('.xlsx') ?
                                <div>
                                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewFile?.data?.file_url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                                </div> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" size='small' variant="outlined" onClick={() => setPreviewFile({ modal: false, data: null })}>Close</Button>
                        </DialogActions>
                    </Dialog> : null
            }
            {
                pageLoader ? <PageLoader /> : null
            }

        </>
    )
}
export default ImportInventory;