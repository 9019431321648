
import pageLayoutHoc from "../../../hocs/pageLayoutHoc";
import userRoleBaseAccessHoc from "../../../hocs/userRoleBaseAccessHoc";
import LLP from "./Containers/LLP";
import Inventory from "./Containers/Inventory";
import MasterComp from "./Containers/MasterComp";
import SoftTime from "./Containers/SoftTime";

export const deletedRoutes = [
    {
        path: "/:asset/:assetType/deleted/llp",
        component: pageLayoutHoc(userRoleBaseAccessHoc(LLP, []), { apps: "LLP", layoutPhase: 2, }),
        key: "LLP",
    },
    {
        path: `/:asset/:assetType/deleted/inventory`,
        component: pageLayoutHoc(userRoleBaseAccessHoc(Inventory, []), { apps: "Inventory", layoutPhase: 2, }),
        key: "Inventory",
    },
    {
        path: `/:asset/:assetType/deleted/mc`,
        component: pageLayoutHoc(userRoleBaseAccessHoc(MasterComp, []), { apps: "mc", layoutPhase: 2, }),
        key: "mc",
    },
    {
        path: `/:asset/:assetType/deleted/soft-time`,
        component: pageLayoutHoc(userRoleBaseAccessHoc(SoftTime, []), { apps: "st", layoutPhase: 2, }),
        key: "st",
    },


];
