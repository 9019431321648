import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { globalPutService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { PageLoader} from '../../../shared_elements';
import GoogleLocationField from '../../../shared_elements/components/GoogleLocationField';
const AddEditInventory = ({ addEditInventory, toggleModalFn, getResponseBack, onOpenAttachments }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [inventory, setInventory] = useState(addEditInventory.data);
    const [error, setError] = useState({});
    const [previewFile, setPreviewFile] = useState({ modal: false, data: null, singlepreview: null })
    const onFieldChange = (key, value) => {
        setInventory({ ...inventory, [key]: value });
    }
    const uploadAttachments = (id, files, type) => {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('files', file, file.name);
        }
        globalPostService(`console/attachments/inventory/${id}/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(type === 'add' ? 'Consignment added successfully' : 'Consignment updated successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    toggleModalFn(); getResponseBack();
                }
                setLoading(false);
            })
    }
    const onAddEditInventory = () => {
        let validationInputs = {};
        validationInputs = {
            description: inventory?.description?.trim()?.length ? '' : 'Please enter Description',
            serial_number: inventory?.serial_number?.trim()?.length ? '' : 'Please enter Serial Number',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if (inventory.id) {
                globalPutService(`console/inventory/${inventory.id}/`, inventory)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            if (inventory?.files?.length) {
                                uploadAttachments(inventory.id, inventory.files, 'edit');
                            } else {
                                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                toggleModalFn(); getResponseBack();
                            }
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            setLoading(false);
                        }
                    })
            } else {
                globalPostService(`console/inventory/`, inventory)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            if (inventory?.files?.length) {
                                uploadAttachments(response.data.data.id, inventory.files, 'add');
                            } else {
                                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                                toggleModalFn(); getResponseBack();
                            }
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            setLoading(false);
                        }
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const handleChipClick = (event) => {
        setPageLoader(true);
        if (event.endsWith('.xlsx') || event.endsWith('.xls') || event.endsWith('.doc') || event.endsWith('.docx')) {
            let formData = new FormData();
            formData.append('file', inventory?.files.find(item => item?.path === event));
            globalPostService(`camo/preview/file/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        setPageLoader(false);
                        setPreviewFile({ modal: true, data: response?.data, singlepreview: false })
                    }
                });

        } else {
            setPageLoader(false);
            setPreviewFile({ modal: true, data: [inventory?.files.find(item => item?.path === event)], singlepreview: true })
        }
    };


    return (
        <>
            <Dialog
                open={addEditInventory.modal}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    {inventory.id ? addEditInventory.mode === 'view' ? 'View' : 'Edit' : 'Add'} Consignment
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    required
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.description}
                                    error={error.description ? true : false}
                                    helperText={error.description ? error.description : ''}
                                    onChange={(e, value) => onFieldChange('description', e.target.value)}
                                    onFocus={() => setError({ ...error, 'description': '' })}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    required
                                    name="serial_number"
                                    label="Serial Number"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.serial_number}
                                    error={error.serial_number ? true : false}
                                    helperText={error.serial_number ? error.serial_number : ''}
                                    onChange={(e, value) => onFieldChange('serial_number', e.target.value)}
                                    onFocus={() => setError({ ...error, 'serial_number': '' })}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.part_number}
                                    onChange={(e, value) => onFieldChange('part_number', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="owner"
                                    label="Owner"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.owner}
                                    onChange={(e, value) => onFieldChange('owner', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <GoogleLocationField
                                    onFieldChange={onFieldChange}
                                    location={inventory.location ? inventory.location : ''}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="linked_asset"
                                    label="Linked Asset"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.linked_asset}
                                    onChange={(e, value) => onFieldChange('linked_asset', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="status"
                                    label="Status"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.status}
                                    onChange={(e, value) => onFieldChange('status', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="remarks"
                                    label="Remarks"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    margin="normal"
                                    value={inventory.remarks}
                                    onChange={(e, value) => onFieldChange('remarks', e.target.value)}
                                    inputProps={{ maxLength: 255 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                {inventory.id && addEditInventory?.data?.attachments?.length ?
                                    <Button onClick={onOpenAttachments} style={{ margin: '4px' }} size='small' variant='outlined' color='primary'>({addEditInventory.data.attachments.length})Attachment(s)</Button> : null
                                }
                                {addEditInventory.mode !== 'view' ?
                                    <>
                                        <div>
                                            <DropzoneArea
                                                acceptedFiles={['.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx']}
                                                filesLimit={10}
                                                maxFileSize={104857600}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview={true}
                                                showPreviews={true}
                                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                                                dropzoneClass="drag-drop-cnt"
                                                maxWidth="sm"
                                                showAlerts={['info', 'error']}
                                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                                clearOnUnmount={true}
                                                onChange={(files) => onFieldChange('files', files)}
                                                previewChipProps={{
                                                    onClick: (event) => {
                                                        handleChipClick(event?.target.textContent);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Max files upload limit is 10 in single time, with supported extension ('.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx').</span>
                                    </> : null
                                }
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} color="primary" size='small' variant="outlined" onClick={() => { toggleModalFn() }}>Close</Button>
                    {addEditInventory.mode !== 'view' ?
                        <Button disabled={isLoading} size='small' color="primary" variant="contained" onClick={() => { onAddEditInventory() }}>
                            {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                        </Button> : null
                    }
                </DialogActions>
            </Dialog>
            {
                previewFile?.modal ?
                    <Dialog
                        open={previewFile?.modal}
                        onClose={() => setPreviewFile({ modal: false, data: null })}
                        style={{ height: "100vh" }}
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogTitle>
                        Consignment Preview
                        </DialogTitle>
                        <DialogContent dividers>
                            {previewFile?.singlepreview ? previewFile?.data?.map((file, index) => (
                                <div key={index}>
                                    {
                                        file?.type === 'application/pdf' ? (
                                            <object type="text/html" data={URL.createObjectURL(file)} style={{ width: '100%', height: '100vh' }}>
                                                <param name="type" value="text/html" />
                                            </object>
                                        ) : file.type.startsWith('image/') && (
                                            <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100%', height: '100vh' }} />
                                        )
                                    }
                                </div>
                            ))
                                :
                                previewFile?.data?.file_name?.endsWith('.xls') || previewFile?.data?.file_name?.endsWith('.xlsx') || previewFile?.data?.file_name?.endsWith('.docx') || previewFile?.data?.file_name?.endsWith('.doc') ?
                                    <div>
                                        <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewFile?.data?.file_url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                                    </div> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" size='small' variant="outlined" onClick={() => setPreviewFile({ modal: false, data: null })}>Close</Button>
                        </DialogActions>
                    </Dialog> : null
            }
            {
                pageLoader ? <PageLoader /> : null
            }
        </>
    )
}
export default AddEditInventory;