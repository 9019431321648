export const OpMainHead = [
    { id: 1, label: "", sortOption: false },
    { id: "", label: "", sortOption: false },
    { id: "fitment", label: "Fitment", sortOption: false, colspan: 10 },
    { id: "removal", label: "Removal", sortOption: false, colspan: 11 },
    { id: "Maintenance", label: "Maintenance", sortOption: false, colspan: 2 },
    { id: "", label: "", sortOption: false },
]
export const OpTableHead = [
    { id: "actions", label: "Actions", sortOption: false },
    { id: "fitment_date", label: "Date", sortOption: true },
    { id: "fitment_operator", label: "Operator", sortOption: true },
    { id: "fitment_aircraft", label: "A/C", sortOption: true },
    { id: "fitment_tail", label: "Tail", sortOption: true },
    { id: "fitment_psn", label: "PSN", sortOption: true },
    { id: "fitment_thrust", label: "Thrust", sortOption: true },
    { id: "fitment_aircraft_flying_hours", label: "A/F Hours",sortOption: true },
    { id: "fitment_aircraft_flying_cycle", label: "A/F Cycle",sortOption: true },
    { id: "fitment_engine_tsn", label: "Engine TSN",sortOption: true },
    { id: "fitment_engine_csn", label: "Engine CSN",sortOption: true },

    { id: "removal_date", label: "Date", sortOption: true },
    { id: "removal_aircraft_flying_hours", label: "A/F Hours",sortOption: true },
    { id: "removal_aircraft_flying_cycle", label: "A/F Cycle",sortOption: true },
    { id: "removal_engine_tsn", label: "Engine TSN",sortOption: true },
    { id: "removal_engine_csn", label: "Engine CSN",sortOption: true },
    { id: "removal_tslsv", label: "TSLSV", sortOption: true },
    { id: "removal_cslsv", label: "CSLSV", sortOption: true },
    { id: "removal_hours_used", label: "Hours Used", sortOption: true },
    { id: "removal_cycle_used", label: "Cycle Used", sortOption: true },
    { id: "removal_egtm", label: "EGTM", sortOption: true ,width:"250px"},
    { id: "removal_event", label: "Event", sortOption: true ,width:"250px"},

    { id: "maint_effective_work_carried_out", label: "Effective Work Carried Out", sortOption: true,width:"250px" },
    { id: "maint_shop", label: "Shop", sortOption: true,width:"250px"},
    { id: "attachments", label: "Attachments" },
];
