import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
const InventoryListing = ({ item, onView, onEdit, onDelete, onOpenAttachments }) => {
	return (
		<TableRow>
			<TableCell>{item?.description || '--'}</TableCell>
			<TableCell>{item?.serial_number}</TableCell>
			<TableCell>{item?.part_number || '--'}</TableCell>
			<TableCell>{item?.owner || '--'}</TableCell>
			<TableCell>{item?.location || '--'}</TableCell>
			<TableCell>{item?.linked_asset || '--'}</TableCell>
			<TableCell>{item?.status || '--'}</TableCell>
			<TableCell align='right'>
				<div style={{ minWidth: '140px' }}>
					<ul className='list-inline flex-centered' style={{ float: 'right' }}>
						{item.attachments.length ?
							<li style={{ cursor: 'pointer' }} onClick={onOpenAttachments} className='list-inline-item'>
								<span className='flex-centered'>
									<span>({item.attachments.length})</span>
									<AttachFileIcon color='primary' fontSize='small' />
								</span>
							</li> : null
						}
						<li style={{ cursor: 'pointer' }} className='list-inline-item' onClick={onView}>
							<VisibilityIcon fontSize='small' color='primary' />
						</li>
						<li style={{ cursor: 'pointer' }} onClick={onEdit} className='list-inline-item'>
							<EditIcon color='primary' fontSize='small' />
						</li>
						<li style={{ cursor: 'pointer' }} onClick={onDelete} className='list-inline-item'>
							<DeleteOutlineIcon color='secondary' fontSize='small' />
						</li>
					</ul>
				</div>
			</TableCell>
		</TableRow>
	)
}
export default InventoryListing