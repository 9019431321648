import React from 'react'
import { Tabs, Tab, Paper } from '@material-ui/core';
import { withRouter } from "react-router-dom";

const DeletedTab = ({match, tabIndex = 'llp',history }) => {
    const archived = window?.location?.search.includes('archived-assets') ? '?archived-assets' : ''
    return (
        <section className="deleted-tab-section">
            <Paper square style={{ marginTop: '10px' }}>
                <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => history.push(`/${match?.params?.asset}/${match?.params?.assetType}/deleted/${newValue}${archived}`)}  >
                    <Tab label='LLP' value="llp" />
                    <Tab label='Inventory' value="inventory" />
                    <Tab label='Master Components' value="mc" />
                    <Tab label='Soft Time' value="soft-time" />
                </Tabs>
            </Paper>
        </section>
    )
}
export default withRouter(DeletedTab);
