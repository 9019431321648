import { globalDeleteService, globalGetService, globalExportService, globalPostService, globalFileUploadService, globalPutService, } from "../../../utils/globalApiServices"
import { downloadFileType, checkApiStatus, permissionCheckFnforCamo, } from "../../../utils"
import { assetType} from '../../../constants';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { trackActivity } from "../../../utils/mixpanel"
import moment from "moment"


export async function getInventoryListApi(queryParams = {}, loaderType, props = {}, searchString = null) {
      this.setState({ pageLoader: true });
      const { asset, assetType } = props?.match?.params;
      try {
          const response = await globalGetService(`camo/inventory/`, {
              ...queryParams,
              per_page: queryParams.per_page ? queryParams?.per_page : 50,
              asset: asset,
              asset_type: assetType,
              search: searchString,
          });

          if (response?.status === 500) {
              props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
              return null;
          } else {
              this.setState({
                  pageLoader: false,
                  assetsDetail: {
                      list: response?.data?.results,
                  },
              });
              delete queryParams.page;
              delete queryParams?.per_page;
              delete queryParams.asset_type;
              delete queryParams.sort;
              delete queryParams.sort_by;
              this.setState((prevState) => ({
                  ...prevState,
                  filter: queryParams,
                  assetsDetail: {
                      ...prevState.assetsDetail,
                      pagination: {
                          ...prevState.assetsDetail?.pagination,
                          total: response?.data?.pagination?.total,
                          per_page: response?.data?.pagination?.per_page,
                          page: parseInt(response?.data?.pagination?.current_page),
                      },
                      new_task_last_used_folder_uuid: response?.data?.new_task_last_used_folder_uuid,
                      last_used_folder_uuid_link_document: response?.data?.last_used_folder_uuid_link_document,
                      checkbox_last_used_folder_uuid: response?.data?.checkbox_last_used_folder_uuid,
                      checkbox_last_used_folder_name: response?.data?.checkbox_last_used_folder_name
                  },
              }));
              return response;
          }
      } catch (error) {
          console.error("Error fetching data:", error);
          return null;
      }
}

export function deleteAssetDetailRow(item, props, per_page) {
  globalDeleteService(`camo/inventory/${item.id}/`).then((response) => {
    if (response.status === 200) {
      props.enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
    }
    if (response.status === 500) {
      props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
    }
    this.getInventoryListApi({ per_page: per_page }, "skeletonLoader", props)
  })
}

export function addTaskFormApi(data, props,addform) {
  if (permissionCheckFnforCamo({ primaryKey: "amp_compliance", keyIndex: "C", })) {
    return new Promise(function (resolve, reject) {
      globalPostService(`camo/inventory/`, data).then((response) => {
        if (response.status === 500 || response.data?.statusCode === 500) {
          props.enqueueSnackbar( response.data?.statusCode === 500 ? response.data?.message :"Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
          addform()
        } else {
          resolve(response)
        }
      })
    })
  }
}

export function fillEditFromApi(item, props) {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/inventory/${item.id}/`).then((response) => {
      if (response.data?.statusCode === 500) {
        props.enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
      } else {
        resolve(response)
      }
    })
  })
}

export function inventorySectionList(props){
  globalGetService(`camo/inventory/section/`)
  .then(response => {
    if (response.status === 500) {
      props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
    } else {
      this.setState({sectionList:response.data.data})
    }
  })
}

export function UpdateTaskFormApi(updateId, updatedData, props, per_page,addform,closeForm) {
  if (
    permissionCheckFnforCamo({ primaryKey: "amp_compliance", keyIndex: "U", })
  ) {
    this.setState({ pageLoader: true })
    globalPutService(`camo/inventory/${updateId}/`, updatedData).then(
      (response) => {
        if (response.status === 500 || response.data?.statusCode === 500) {
          props.enqueueSnackbar( response.data?.statusCode === 500 ? response.data?.message :"Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
          this.setState({ pageLoader: false })
          addform()
        } else {
          if (response) {
            this.setState({ pageLoader: false })
            closeForm()
            props.enqueueSnackbar("Record Updated Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
          }
          this.getInventoryListApi({ per_page: per_page }, "skeletonLoader", props)
        }
      }
    )
  }
}

export function uploadFileApi(uploadId, formdata, props, per_page, haveAttachments) {
  this.setState({ pageLoader: true });
  if (haveAttachments) {
    globalFileUploadService(`camo/attachment/upload/`, formdata).then(
      (response) => {
        if (checkApiStatus(response)) {
          this.setState({ pageLoader: false });
          if (response.data.data.length > 0) {
          }
          this.getInventoryListApi({ per_page: per_page }, "skeletonLoader", props);
        } else {
          props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
        }
      }
    );
  } else {
    this.getInventoryListApi({ per_page: per_page }, "skeletonLoader", props);
    props.enqueueSnackbar("Data Submitted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
    this.setState({ pageLoader: false });
  }
}

export function exportAssetDetailListApi(queryParams = {}, loaderType, props = {}) {
  this.setState({ pageLoader: true })
  const date = new Date()
  const todayeDate = moment(String(date)).format("DD-MM-YYYY")

  const { asset, assetType } = this.props.match.params
  delete queryParams.asset_type
  globalExportService(`/camo/inventory/${asset}/${assetType}/export/`, {
    ...queryParams,
  }).then((response) => {
    if (response.status === 500) {
      props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
    } else {
      this.setState({ pageLoader: false })
      downloadFileType(response.data, `Inventory_${todayeDate}`, queryParams.download)
      this.props.enqueueSnackbar("Download of Inventory List Successful", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
      trackActivity("Item Exported", { page_title: "Asset Listing", })
    }
  })
}

export function deleteAttachment(id, props) {
  globalDeleteService(`camo/attachment/${id}/delete/`).then((response) => {
    if (response.status === 500) {
      props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
    } else {
      if (response) {
        props.enqueueSnackbar("Attachment Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
      }
    }
  })
}

export function importFileApi(formData, props, isXLSX, per_page) {
  if (isXLSX) {
    this.setState({ pageLoader: true })
    const { asset, assetType } = this.props.match.params
    globalFileUploadService(`camo/inventory/${asset}/${assetType}/import/`, formData)
      .then((response) => {
        this.setState({ pageLoader: false })
        if (response.data.statusCode == 200) {
          props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
        } else {
          props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
        }
        this.getInventoryListApi({ per_page: per_page }, "skeletonLoader", props);
      })
      .catch((err) => {
        props.enqueueSnackbar("Please import proper file", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
      })
  } else {
    props.enqueueSnackbar("Please Import only .XLS File", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
  }
}

export function DownloadSampleFile(queryParams = {},loaderType, props = {},basicDetails) {
  
  const { asset, assetType } = props.match.params
  globalExportService(`camo/inventory/${asset}/${assetType}/sample/`,{...queryParams}).then((response) => {
    if (response.status === 500) {
      props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
    } else {
      downloadFileType(response.data, `SampleFile_Inventory_${basicDetails?.esn}`, queryParams.download)
      trackActivity("Item Exported", { page_title: "Asset Listing", })
    }
  })
}

export function exportMasterApi(queryParams = {}, loaderType, props = {}) {
	this.setState({ pageLoader: true });
	const date = new Date();
	const todayeDate = moment(String(date)).format("DD-MM-YYYY");

	const { asset, assetType } = this.props.match.params;
	delete queryParams.asset_type;
	globalExportService(`/camo/smbc_master_excel/${asset}/${assetType}/export/`)
		.then((response) => {
			if (response.status === 500) {
				props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
			} else {
				this.setState({ pageLoader: false });
				downloadFileType(response.data, `Master_Export_${todayeDate}`, queryParams.download);
				this.props.enqueueSnackbar("Master Export Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				trackActivity("Master Export", {
					page_title: "Asset Listing",
				});
			}
		});
}
export function bulkDelete(props, bulkOperation, per_page) {
  this.setState({ pageLoader: true });
  const { asset, assetType } = props?.match?.params;
  globalDeleteService(`camo/inventory/bulk_delete/${assetType}/${asset}/`, { delete_ids: bulkOperation.ids.map(optionItem => optionItem.id) }).then((response) => {
      if (response?.data?.statusCode === 200) {
        this.setState({ pageLoader: false });
          props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
      } else {
          props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
      }
      this.getInventoryListApi({ per_page: per_page }, "skeletonLoader", props);
      this.setState({ bulkOperation: { modal: false, ids: [], title: '', type: '' } })
  });
}

export function onArchiveAsset(props,slug){
  globalGetService(`console/archive-asset/${assetType[props?.match?.params?.assetType]}/${slug}/true/`)
      .then(response => {
          if (checkApiStatus(response)) {
              props.enqueueSnackbar('Archive Status Updated Successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
              props.history.push("/assets-listing")
          }
      })
}

export async function DownloadAllApi(queryParams = {}, pageLoader) {
  this.setState({ pageLoader: true })
  const attachment_list = queryParams.item.download_attachments;
  for (var i = 0; i < attachment_list.length; i++) {
    var encodedURL = attachment_list[i][1];
    var decodedURL = decodeURIComponent(encodedURL);
    attachment_list[i][1] = decodedURL;
  }
  const zip = new JSZip();
  const folder = zip.folder('DownloadedFiles');
  try {
    const downloadPromises = attachment_list.map(async (entry) => {
      const name = entry[0];
      const url = entry[1] || '';

      const res = await fetch(url);

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const blob = await res.blob();
      folder.file(name, blob);
    });

    await Promise.all(downloadPromises);

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'Inventory.zip');
    this.setState({ pageLoader: false })
  } catch (error) {
    console.error('Error:', error);
  }
}
