import React from 'react'
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"

function EngOnOffWingList({ basicDetailData }) {
    
    return (
        <>
            <TableHead className="table-head">
                <TableRow>
                    <TableCell>Wing Status</TableCell>
                    {basicDetailData?.on_wing_status && <TableCell>  On Wing MSN </TableCell>}
                    {basicDetailData?.on_wing_status && <TableCell>  On Wing Owner </TableCell>}
                    {basicDetailData?.off_wing_status && <TableCell> Off wing Condition </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell> TSN of Engine Removal </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell> CSN of Engine Removal </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell> Date of Engine Removal </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell> Reason of Engine Removal </TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {basicDetailData?.on_wing_status ? <TableCell className="font-b"> On Wing</TableCell> : <TableCell className="font-b"> Off Wing</TableCell>}
                    {basicDetailData?.on_wing_status && <TableCell className="font-b">{basicDetailData?.on_wing_msn || '--'}</TableCell>}
                    {basicDetailData?.on_wing_status && <TableCell className="font-b">{basicDetailData?.on_wing_lessor || '--'} </TableCell>}
                    {basicDetailData?.off_wing_status && <TableCell className="font-b">{basicDetailData?.off_wing_status?.label || '--'} </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell className="font-b">{basicDetailData?.off_wing_detail?.tsn_at_removal || '--'} </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell className="font-b">{basicDetailData?.off_wing_detail?.csn_at_removal || '--'} </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell className="font-b">{basicDetailData?.off_wing_detail?.date_of_removal || '--'} </TableCell>}
                    {basicDetailData?.off_wing_detail && <TableCell className="font-b">{basicDetailData?.off_wing_detail?.reason_for_removal || '--'} </TableCell>}
                </TableRow>
            </TableBody>
            <TableHead className="table-head">
                <TableRow>
                    {basicDetailData?.off_wing_detail && <TableCell> Preservation Performed </TableCell>}
                    {
                        basicDetailData?.off_wing_status?.value === 2 ? <>
                            <TableCell> Release Date of the Assembly </TableCell>
                            <TableCell> Shop Visit Report Provided </TableCell>
                            <TableCell> Type of Shop Visit </TableCell>
                            <TableCell> Expected Date of Shipment Back to Lessee </TableCell>
                            <TableCell> Facility Name </TableCell> </> :
                            basicDetailData?.off_wing_status?.value === 3 ? <>
                                <TableCell> Expected Release Date of the Assembly? </TableCell>
                                <TableCell> Induction Date of Assembly </TableCell>
                                <TableCell> Wokr Scope Status </TableCell>
                                <TableCell> Facility Name </TableCell>
                                <TableCell> Facility Location </TableCell>
                            </> :
                                basicDetailData?.off_wing_status?.value === 4 ?
                                    <>
                                        <TableCell> Expected Date of Installation On Aircraft </TableCell>
                                        <TableCell> Shop Visit Performed </TableCell>
                                        <TableCell> Shop Visit Report Status </TableCell>
                                        <TableCell> Asset Location </TableCell>
                                    </> :
                                    basicDetailData?.off_wing_status?.value === 5 &&
                                    <>
                                        <TableCell> Shop Visit Requirement </TableCell>
                                        <TableCell> Expected Dtae of Shipment Shop </TableCell>
                                        <TableCell> Which Shop has Been Selected ? </TableCell>
                                        <TableCell> Asset Location </TableCell>
                                    </>
                    }

                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {basicDetailData?.off_wing_detail && <TableCell className="font-b">{basicDetailData?.off_wing_detail?.preservation_performed ? "YES" : "NO" || '--'} </TableCell>}
                    {
                        basicDetailData?.off_wing_status?.value === 2 ? <>
                            <TableCell className="font-b">{basicDetailData?.off_wing_detail?.release_date_of_the_engine || '--'} </TableCell>
                            <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_report_provided ? "YES" : "NO" || '--'} </TableCell>
                            <TableCell className="font-b">{basicDetailData?.off_wing_detail?.type_of_shop_visit?.label || '--'} </TableCell>
                            <TableCell className="font-b">{basicDetailData?.off_wing_detail?.expected_date_of_shipment_back_to_lessee || '--'} </TableCell>
                            <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_name || '--'} </TableCell>
                        </> :
                            basicDetailData?.off_wing_status?.value === 3 ? <>
                                <TableCell className="font-b">{basicDetailData?.off_wing_detail?.expected_release_date_of_the_engine || '--'} </TableCell>
                                <TableCell className="font-b">{basicDetailData?.off_wing_detail?.induction_date_of_engine || '--'} </TableCell>
                                <TableCell className="font-b">{basicDetailData?.off_wing_detail?.work_scope_status?.label || '--'} </TableCell>
                                <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_name || '--'} </TableCell>
                                <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_location || '--'} </TableCell>
                            </> :
                                basicDetailData?.off_wing_status?.value === 4 ?
                                    <>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.expected_date_of_installation_on_wing || '--'} </TableCell>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_report_provided ? "YES" : "NO" || '--'} </TableCell>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_report_status?.label || '--'} </TableCell>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.off_wing_location || '--'} </TableCell>

                                    </> :
                                    basicDetailData?.off_wing_status?.value === 5 &&
                                    <>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_requirement?.label || '--'} </TableCell>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.expected_date_of_shipment_to_shop || '--'} </TableCell>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.selected_shop || '--'} </TableCell>
                                        <TableCell className="font-b">{basicDetailData?.off_wing_detail?.off_wing_location || '--'} </TableCell>
                                    </>
                    }

                </TableRow>
                <TableRow>

                </TableRow>
            </TableBody>
            <TableHead className="table-head">
                <TableRow>
                    {basicDetailData?.off_wing_status?.value === 2 && <TableCell> Facility Location </TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {basicDetailData?.off_wing_status?.value === 2 && <TableCell className="font-b">{basicDetailData?.off_wing_detail?.shop_visit_location || '--'} </TableCell>}
                </TableRow>
            </TableBody>
        </>
    )
}

export default EngOnOffWingList