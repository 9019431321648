import React, { Fragment } from "react";
import { withRouter,NavLink } from "react-router-dom";
import SideNav from "../SideNav";
import { getLocalStorageInfo } from "../../../utils";
import config from '../../../config';

function MntSideNav(props) {
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
	const asset = props.match.params?.asset
	const assetType = props.match.params?.assetType
	const archived = props?.location?.search.includes('archived-assets') ? '?archived-assets' : ''
	
	return (
		<Fragment>
			<ul className="list-unstyled">
				{smbcInstance && assetType == 2 ? <SideNav to={`/${asset}/${assetType}/op_history/list`} title="Op. History" /> : null}
				{assetType == 1 ? <SideNav to={`/${asset}/${assetType}/amp/list`} title="AMP/MRB Compliance" /> : null}
				<SideNav to={`/${asset}/${assetType}/ad/list${archived}`} title={smbcInstance ? "AD Status" : "Airworthiness Directives (ADs)"} />
				{smbcInstance ? null : <SideNav to={`/${asset}/${assetType}/ht/list${archived}`} title="Hard Time Components" />}
				<SideNav to={`/${asset}/${assetType}/sb/list${archived}`} title={smbcInstance ? "SB Status" : "Service Bulletins (SB)"} />
				{smbcInstance ? null : <SideNav to={`/${asset}/${assetType}/occm/list${archived}`} title="OC-CM Components" />}
				{assetType == 1 ? <SideNav to={`/${asset}/${assetType}/sr/list`} title="Structure Repair" /> : null}
				{assetType == 1 ? null : <SideNav to={`/${asset}/${assetType}/llp/list${archived}`} title={smbcInstance ? "LLP Status" : "Life Limited Parts (LLPs)"} />}
				{assetType == 1 ? <SideNav to={`/${asset}/${assetType}/mods/list`} title="Modifications" /> : null}
				{assetType == 1 ? null : <SideNav to={`/${asset}/${assetType}/inventory/list${archived}`} title="Inventory List" />}
				{assetType == 1 ? null : <SideNav to={`/${asset}/${assetType}/shopVisit/list${archived}`} title="Shop Visits" />}
				{assetType == 2 && <SideNav to={`/${asset}/${assetType}/utilization/list${archived}`} title="Utilization" />}
				{assetType == 2 && <SideNav to={`/${asset}/${assetType}/softTime/list${archived}`} title="Soft Time" />}
				{assetType == 2 && <SideNav to={`/${asset}/${assetType}/master_components/list${archived}`} title="Master Components" />}
				{smbcInstance ? null : <SideNav to={`/dashboard`} title="Analytics" />}
				{assetType == 2 && <li><NavLink className={props?.match?.path.includes("deleted") ? "active" : ""} class={props?.match?.path.includes("deleted") ? "active" : ""} to={`/${asset}/${assetType}/deleted/llp${archived}`}> Deleted Section</NavLink></li>}
			</ul>
		</Fragment>
	);
}

export default withRouter(MntSideNav);
