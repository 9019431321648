import React, { useState } from "react";
import { useSnackbar } from 'notistack';
import axios from 'axios'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Grid } from '@material-ui/core';
import { DeletePopUp, EmptyCollection, PageLoader } from '../../../shared_elements'
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close"
import VisibilityIcon from '@material-ui/icons/Visibility';
import { globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
const InventoryAttachments = ({ inventoryAttachments, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({ modal: false, data: null })
    const onDeleteAttachment = () => {
        setLoading(true)
        globalDeleteService(`console/attachments/inventory/${inventoryAttachments.data.id}/${deleteAttachment.data.uuid}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteAttachment({ modal: false, data: null });
                    getResponseBack(deleteAttachment.data.uuid);
                }
                setLoading(false)
            })
    }
    const onDownloadAttachment = (url, fileName) => {
        axios.get(url, { responseType: 'arraybuffer', })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click()
            })
            .catch(error => { })
    }
    return (
        <div class="getAttachmentDialogue">
            <Dialog
                open={inventoryAttachments.modal}
                onClose={toggleModalFn}
                id="htDialog"
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle style={{ padding: "9px 19px" }} id="scroll-dialog-title">
                    Attachment(s)
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{ width: '480px' }}>
                        {inventoryAttachments?.data?.attachments?.length ?
                            <>
                                {inventoryAttachments.data.attachments.map((item, index) =>
                                    <div className="flex-centered" >
                                        {(() => {
                                            let fileInfo = item.name.split('_');
                                            fileInfo.pop();
                                            let fileName = `${fileInfo.join('_')}.${item.extension}`
                                            return (
                                                <>
                                                    <p style={{ width: '80%' }} > {fileName}</p>
                                                    <ul style={{ marginLeft: 'auto', cursor: 'pointer' }} className="list-inline">
                                                        <li className="list-inline-item" style={{ display: 'none' }}>
                                                            <VisibilityIcon fontSize="small" color='primary' />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a onClick={() => onDownloadAttachment(item.url, fileName)} download={fileName}>
                                                                <Tooltip title="Download">
                                                                    <SaveAltIcon color="primary" fontSize="small" style={{ fontSize: '27px' }} />
                                                                </Tooltip>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Tooltip title="Remove">
                                                                <button className="downloadIcon" onClick={() => setDeleteAttachment({ modal: true, data: item })} > <CloseIcon style={{ fontSize: '27px' }} color="secondary" /> </button>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>
                                                </>
                                            )
                                        })()}
                                    </div>
                                )}
                            </> :
                            <div style={{ textAlign: 'center', padding: '15px 0' }}>
                                <EmptyCollection title='No Attachment Available' />
                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="outlined" size='small' onClick={toggleModalFn}> Close </Button>
                </DialogActions>
            </Dialog>
            {deleteAttachment.modal ?
                <DeletePopUp
                    modal={deleteAttachment.modal}
                    title='Delete Attachment'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteAttachment({ modal: false, data: null })}
                    deleteRecordFn={onDeleteAttachment}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default InventoryAttachments;