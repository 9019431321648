import { React, useState, useEffect, Fragment } from 'react'
import { Table, TableBody, Paper, Button, Grid, TextField, Tooltip, InputAdornment, TablePagination, Dialog } from "@material-ui/core";
import { globalGetService, globalDeleteService, globalExportService } from '../../../utils/globalApiServices';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { InventoryListing, InventoryHeader, AddEditInventory, InventoryAttachments, ImportInventory } from '../components';
import { downloadFileType, checkApiStatus } from '../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp, ExportMenu } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/components/filter_component';
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import { inventoryObj, inventoriesFilters } from '.';
import Pagination from '../../../shared_elements/components/Pagination';

function Inventory({ match }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isLoading, setLoading] = useState(false);
	const [skeletonLoader, setSkeletonLoader] = useState(false);
	const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
	const [filter, setFilter] = useState({});
	const [inventories, setInventories] = useState({ list: [], pagination: { page: 1, per_page: 20, total: 100 } });
	const [addEditInventory, setAddEditInventory] = useState({ modal: false, mode: '', data: null });
	const [inventoryAttachments, setInventoryAttachments] = useState({ modal: false, data: null })
	const [deleteInventory, setDeleteInventory] = useState({ modal: false, data: null });

	useEffect(() => {
		getInventories({ ...filter, per_page: 20 }, 'skeletonLoader')
	}, [])
	const getInventories = (query = {}, loaderType) => {
		loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
		globalGetService(`console/inventory/`, query)
			.then(response => {
				if (checkApiStatus(response)) {
					setInventories(response.data.data);
					 delete query.page
					 delete query.per_page
					 delete query.sort
					 delete query.sort_by
					setFilter(query)
				}
				loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
			})
	}
	const exportInventories = (extension) => {
		setLoading(true);
		let queryParams = {}
        if (sortInfo?.sort === '' &&  sortInfo?.sort_by === '') {
            queryParams= Object.assign({}, filter);
        }else{
            queryParams= Object.assign({}, filter,sortInfo);
        }
		globalExportService(`console/inventory/`, { download: extension, ...queryParams })
			.then(response => {
				setLoading(false);
				downloadFileType(response?.data, 'Inventories', extension)
			});
	}
	const onDeleteInventory = () => {
		setLoading(true);
		globalDeleteService(`console/inventory/${deleteInventory.data.id}/`)
			.then(response => {
				if (checkApiStatus(response)) {
					setLoading(false);
					enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
					setDeleteInventory({ modal: false, data: null });
					getInventories({ ...filter, per_page: inventories?.pagination?.per_page || 20 })
				}
			})
	}
	const onAttachmentDelete = (uuid) => {
		setInventoryAttachments({
			...inventoryAttachments, data: {
				...inventoryAttachments.data,
				attachments: inventoryAttachments.data.attachments.filter(item => item.uuid !== uuid)
			}
		});
		if (addEditInventory.modal) {
			setAddEditInventory({
				...addEditInventory,
				data: {
					...addEditInventory.data,
					attachments: addEditInventory.data.attachments.filter(item => item.uuid !== uuid)
				}
			})
		}
		getInventories({ ...filter, per_page: inventories?.pagination?.per_page || 20 }, 'pageLoader');
	}
	const createSortHandler = (key) => {
		let sortQuery = { sort: key };
		if (sortInfo.sort === key) {
			sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
		} else {
			sortQuery = { ...sortQuery, sort_by: 'asc' }
		}
		setSortInfo(sortQuery)
		getInventories({ ...filter, ...sortQuery, page: 1, per_page: inventories?.pagination?.per_page || 20 }, 'pageLoader');
	}
   const filterObj= Object.assign({},filter, delete filter.sort ,delete filter.sortInfo)
	return (
		<section className='camo-fleet-module'>
			{skeletonLoader ? <STableLoader count={10} /> :
				<>
					<Paper square variant='outlined' style={{ padding: '5px 10px', marginBottom: "8px" }}>
						<Grid container spacing={1} alignItems='center'>
							<Grid item md={9}>
								<div style={{ marginTop: "2px" }}>
									<FilterComponent
										filter={filterObj}
										filterMenu={inventoriesFilters}
										getResponseBack={(applyFilter) => getInventories({ ...applyFilter, page: 1, per_page: inventories?.pagination?.per_page || 20 }, 'pageLoader')}
									/>
								</div>
							</Grid>
							<Grid item md={3}>
								<ul className='list-inline' style={{ float: 'right' }}>
									<li className="list-inline-item">
										<ImportInventory getResponseBack={() => getInventories({ ...filter, per_page: inventories?.pagination?.per_page || 20 }, 'pageLoader')} />
									</li>
									<li className='list-inline-item' style={{ marginRight: "5px", marginLeft: "5px" }}>
										<Button onClick={() => setAddEditInventory({ modal: true, mode: 'add', data: inventoryObj })} color='primary' size="small" variant="contained">ADD</Button>
									</li>
									<li className='list-inline-item' style={{ marginRight: "5px" }}>
										<ExportMenu
											exportReportFn={(file) => exportInventories(file.extension)}
											files={[{ title: 'EXCEL', extension: 'xls' }]}
											title='EXPORT'
										/>
									</li>

								</ul>
							</Grid>
						</Grid>
					</Paper>
					<Paper variant='outlined' square style={{ padding: '5px 10px', marginBottom: "5px" }}>
						<div style={{ height: !inventories.list.length ? 'auto' : `${window.innerHeight - 226}px`, overflow: 'auto' }}>
							<Table className='mui-table-format' stickyHeader={true}>
								<InventoryHeader
									sortInfo={sortInfo}
									createSortHandler={createSortHandler}
								/>
								<TableBody>
									{inventories.list.map((item, index) =>
										<InventoryListing
											item={item}
											onView={() => setAddEditInventory({ modal: true, mode: 'view', data: item })}
											onEdit={() => setAddEditInventory({ modal: true, mode: 'edit', data: item })}
											onDelete={() => setDeleteInventory({ modal: true, data: item })}
											onOpenAttachments={() => setInventoryAttachments({ modal: true, data: item })}
										/>
									)}
								</TableBody>
							</Table>
							{!inventories?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
						</div>
						<Pagination
							pagination={inventories.pagination}
							onChangePage={(event, newPage) => getInventories({ ...filter, page: newPage + 1, per_page: inventories?.pagination?.per_page || 20 }, 'pageLoader')}
							onChangeRowsPerPage={(event) => getInventories({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
						/>
					</Paper>
				</>
			}
			{addEditInventory.modal ?
				<AddEditInventory
					addEditInventory={addEditInventory}
					toggleModalFn={() => setAddEditInventory({ modal: false, mode: '', data: null })}
					getResponseBack={() => getInventories({ ...filter, per_page: inventories?.pagination?.per_page || 20 }, 'pageLoader')}
					onOpenAttachments={() => setInventoryAttachments({ modal: true, data: addEditInventory.data })}
				/> : null
			}
			{deleteInventory.modal ?
				<DeletePopUp
					modal={deleteInventory.modal}
					title='Delete Consignment'
					content={`Are you sure, you want to Delete?`}
					toggleModalFn={() => setDeleteInventory({ modal: false, data: null })}
					deleteRecordFn={onDeleteInventory}
				/> : null
			}
			{inventoryAttachments.modal ?
				<InventoryAttachments
					inventoryAttachments={inventoryAttachments}
					getResponseBack={onAttachmentDelete}
					toggleModalFn={() => { setInventoryAttachments({ modal: false, data: null }); }}
				/> : null
			}
			{isLoading ? <PageLoader /> : null}
		</section >
	)
}

export default withRouter(Inventory)